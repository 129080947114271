export const items = [
  {
    label: "Chief Executive Officer (CEO)",
    value: "Chief Executive Officer (CEO)",
  },
  {
    label: "Chief Technology Officer (CTO)",
    value: "Chief Technology Officer (CTO)",
  },
  {
    label: "Chief Operating Officer (COO)",
    value: "Chief Operating Officer (COO)",
  },
  {
    label: "Chief Information Officer (CIO)",
    value: "Chief Information Officer (CIO)",
  },
  {
    label: "Chief Financial Officer (CFO)",
    value: "Chief Financial Officer (CFO)",
  },
  {
    label: "Chief Security Officer (CSO) or Chief Information Security Officer (CISO)",
    value: "Chief Security Officer (CSO) or Chief Information Security Officer (CISO)",
  },
  { 
    label: "Chief Data Officer (CDO)",
    value: "Chief Data Officer (CDO)"
  },
  {
    label: "Chief Human Resources Officer (CHRO)",
    value: "Chief Human Resources Officer (CHRO)",
  },
  {
    label: "Chief Product Officer (CPO)",
    value: "Chief Product Officer (CPO)",
  },
  {
    label: "Chief Development Officer (CDO)",
    value: "Chief Development Officer (CDO)",
  },
  {
    label: "Chief Strategy Officer (CSO)",
    value: "Chief Strategy Officer (CSO)",
  },
  { label: "Others", value: "Others" },
];


export const industry = [
  {
    label: "Software Services",
    value: "Software_Services",
  },
  {
    label: "IoT",
    value: "IoT",
  },
  {
    label: "IIoT",
    value: "IIoT",
  },
  {
    label: "AI/ML",
    value: "AI/ML",
  },
  {
    label: "Cloud Computing",
    value: "Cloud Computing",
  },
  {
    label: "Cybersecurity",
    value: "Cyber_security",
  },
  {
    label: "Data Analytics",
    value: "Data_Analytics",
  },
  {
    label: "EdTech",
    value: "Ed_Tech",
  },
  {
    label: "FinTech",
    value: "FinTech",
  },
  {
    label: "HealthTech",
    value: "Health_Tech",
  },

  { label: "Others", value: "Others" },
];

export const removableFields = [
  "key",
  "firstName",
  "lastName",
  "company",
  "email",
  "createdAt",
  "phone",
  "industry",
  "designation",
  "status",
  // "companyLogo"

]

export const employeeCount = [
    { label: '0-10', value: '0-10' },
    { label: '10-20', value: '10-20' },
    { label: '20-50', value: '20-50' },
    { label: '50-100', value: '50-100' },
    { label: '100-200', value: '100-200' },
    { label: '200-500', value: '200-500' },
    { label: '500-1000', value: '500-1000' },
    { label: '1000+', value: '1000+' },
]